<template>
  <v-container>
    <v-row no-gutters>
      <v-col class="col-12 content-header text-left">
        <h1 class="content-title">
          Reset password
        </h1>
        <div class="content-subtitle">
          Email with instructions sent.
        </div>
      </v-col>
    </v-row>
    <v-row class="content-body justify-center align-center">
      <v-col class="col-12 d-flex justify-center col py-0">
        <v-col class="col-12 mb-4">
          <v-btn
            link
            to="/"
            x-large
            color="primary"
            class="mt-8 home-button"
          >
            Home
          </v-btn>
        </v-col>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'ResetPassword',
  head: {
    title: {
      inner: 'Reset password complete'
    }
  }
}
</script>

<style scoped>
  .home-button {
    width: 100%;
  }
</style>